import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, memo, useMemo } from 'react';
import { getPageName } from 'utils/catalog/catalog-seo';
import { getProductUrlFromSlug } from 'utils/url';

const NUM_OF_SEO_PRODUCTS = 10;

const DynamicTable: FC = () => {
  const { t } = useTranslation('catalog');
  const { locale } = useRouter();
  const { data } = useDynamicCatalogContext();
  const { seoInfo, pageInfo } = useStaticCatalogContext();
  const { products } = data;
  const { month, year, dateString } = useMemo(() => {
    const currentDate = new Date();

    return {
      month: currentDate.toLocaleString(locale, { month: 'long' }),
      year: currentDate.getFullYear(),
      dateString: currentDate.toLocaleDateString(locale),
    };
  }, [locale]);
  const pageName = useMemo(() => getPageName(pageInfo, seoInfo, t), [pageInfo, seoInfo, t]);

  if (!products?.Products || !pageName) {
    return null;
  }

  const seoProducts = products.Products.slice(0, NUM_OF_SEO_PRODUCTS);

  return (
    <div>
      <h2 className="mb-3 mt-10 font-bold uppercase">
        {t('The Latest Price for {{pageName}} | {{month}} {{year}}', {
          pageName,
          month,
          year,
        })}
      </h2>
      <table className="w-full table-auto border-collapse">
        <caption className="caption-bottom pt-4 text-xs text-grey-100">
          {t('Data is updated on {{updatedDate}}', {
            updatedDate: dateString,
          })}
        </caption>
        <thead>
          <tr>
            <th className="border p-4 text-left font-medium text-grey-100">{t('Product Name')}</th>
            <th className="border p-4 text-left font-medium text-grey-100">{t('Price')}</th>
          </tr>
        </thead>
        <tbody>
          {seoProducts.map((product) => (
            <tr key={product.ConfigSku}>
              <td className="border border-slate-200 p-3  text-grey-100">
                <Link href={getProductUrlFromSlug(product.ProductUrl || '')}>
                  <a>{t('Price {{productName}}', { productName: product.Name })}</a>
                </Link>
              </td>
              <td className="whitespace-nowrap border border-slate-200 p-3 text-grey-100">
                {product.SpecialPrice || product.Price}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(DynamicTable);
