import clsx from 'clsx';
import Link from 'next/link';
import React, { FC } from 'react';

export interface RelatedInternalLinkType {
  Id: string;
  Name: string;
  Url: string;
}

interface Props {
  title: string;
  links: RelatedInternalLinkType[];
}

const RelatedInternalLinks: FC<Props> = ({ title, links }) => {
  return (
    <div className="catalog-info text-grey-80">
      <h2>{title}</h2>
      <ul className="mt-1 block">
        {links.map((link, id) => (
          <li
            key={link.Id}
            className={clsx(
              'my-1 inline-block whitespace-nowrap px-3 first:-ml-3 hover:underline',
              {
                'border-l border-solid border-slate-900': id !== 0,
              },
            )}
          >
            <Link href={link.Url}>
              <a>{link.Name}</a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RelatedInternalLinks;
